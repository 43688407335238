import React,{history,useEffect, useState, useMemo } from "react";
import services from "../../services/services";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $ from 'jquery';

const AddClient = ({editClient, isOpenModal}) => {
    const [showCompanyList, setShowCompanyList] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [clientName, setclientName] = useState('');
    const [initialClientName, setInitialClientName] = useState('');
    const [clientCompany, setClientCompany] = useState(sessionStorage.getItem('adminCompany'));
    const [initialCompany, setInitialCompany] = useState('');
    const [showClientInfo, setShowClientInfo] = useState('');
    const [sesAdminCompany, setSesAdminCompany] = useState(sessionStorage.getItem('adminCompany'));
    const [sesUserRole, setSesUserRole] = useState(sessionStorage.getItem('userRole'));
    const [sesUserCompanyId, setSesUserCompanyId] = useState(sessionStorage.getItem('userCompanyId'));
    const [clientvalidation, setClientvalidation] = useState(0);
    const [editclientvalidation, setEditClientvalidation] = useState(0);
    const [companyvalidation, setcompanyvalidation] = useState(0);
    const [editcompanyvalidation, setEditCompanyvalidation] = useState(0);
    const [showLoader, setShowLoader] = useState(1);

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all",mode: "onBlur"
    });

    useEffect(() => {
        // Attach the event listener to the modal
        const modalElement = document.getElementById('exampleModal2');
        const handleModalHidden = () => resetForm();
        
        modalElement.addEventListener('hidden.bs.modal', handleModalHidden);
    
        return () => {
            // Cleanup the event listener when the component unmounts
            modalElement.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, []);
    
    useEffect(() => {
        if (!isOpen) {
            resetForm(); // Reset the form when the modal is closed
        }
    }, [isOpen]);
    
    const resetForm = () => {
        $("#entryform").trigger("reset");
        $('#client_name').val('');
        setClientvalidation(0);
        setEditClientvalidation(0);
        setcompanyvalidation(0);        
        setEditCompanyvalidation(0);  
        $('#successMessageClient').hide();
        $('#updatesuccessMessageClient').hide();
        $('#failuresuccessMessageClient').hide();
        $('#validationMessage').hide();
        $('#editvalidationMessage').hide();
    
     };


    useEffect(() => {
        setShowLoader(0);
        getCompanyList();
        if(isOpenModal == 1){
            $('#successMessageClient').hide();
            $('#updatesuccessMessageClient').hide();
            $('#failuresuccessMessageClient').hide();
            $('#validationMessage').hide();
            $('#editvalidationMessage').hide();

            $("#entryform").trigger("reset");
            $("#exampleModal2").trigger("reset");
        }
        if(!isEmpty(editClient)){
            //edit
            $('#clientadd').hide();
            $('#clientedit').show();
            
            getClientInfo(editClient);
        }
        else {
            $('#clientadd').show();
            $('#clientedit').hide();
        }
        $('#successMessageClient').hide();
        $('#updatesuccessMessageClient').hide();
        $('#failuresuccessMessageClient').hide();
        $('#validationMessage').hide();
        $('#editvalidationMessage').hide();
    },[editClient, isOpenModal]);

    function getCompanyList(){
        var companystatus = 1;
        services.getCompanyList(companystatus).then((res) => {
            if(res){
                
                if(res.data.data != null){
                    console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@101")
                    console.log(JSON.stringify(res.data.data));
                    //setShowCompanyList(res.data.data);
                    let filteredCompanyList = [];
                    // Filter the company list to keep only the one that matches sesAdminCompany
                    if(sesUserRole == 'Manager'){
                         filteredCompanyList = res.data.data.filter(company => company.id === parseInt(sesUserCompanyId));
                    }else{
                         filteredCompanyList = res.data.data.filter(company => company.id === parseInt(sesAdminCompany));
                    }
                    
                    console.log("sesAdminCompany");
                    console.log(sesAdminCompany);
                    console.log(JSON.stringify(filteredCompanyList));
                    // Set the filtered company list
                    setShowCompanyList(filteredCompanyList);
                }
            }
        });
    }

    function getClientInfo(clientid) {
        services.getClientInfoById(clientid).then((res) => {
            
            if(res){
                if(res.data.data != null){
                    setShowClientInfo(res.data.data);

                    const client_name = res.data.data.client_name;
                    // Capitalize the first letter of each word
                    const capitalizedClientNameValue = client_name
                        .split(' ')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ');

                    setclientName(capitalizedClientNameValue);

                    //setclientName(res.data.data.client_name);
                    setInitialClientName(res.data.data.client_name);
                    setClientCompany(res.data.data.company_id);
                    setInitialCompany(res.data.data.company_id);
                }
            }
        });
    }

    const submitValue=()=>
    {
        if(!isEmpty(editClient)){
            //Edit form
            var validation = 0;
            if(isEmpty($('#editclient_name').val())){
                validation = 1;
                setEditClientvalidation(1);
            } else {
                setEditClientvalidation(0);
            }

            if(isEmpty($('#editcompany_name').val())){
                validation = 1;
                setEditCompanyvalidation(1);
            } else {
                setEditCompanyvalidation(0);
            }

            if(validation == 1){
                $('#editvalidationMessage').show();
                return false;
            } else {
                setShowLoader(1);
                    //direct update
                    const requestObject = {
                        "client_name": $('#editclient_name').val(),
                        "address1": null,
                        "address2": null,
                        "city": null,
                        "state": null,
                        "zip": null,
                        "company_id": sesAdminCompany,//$('#editcompany_name').val(),
                        "status": 1
                    }
                    var accessTokenIn=sessionStorage.getItem("accToken");
                    // const requestOptions = {
                    //     method: 'PUT',
                    //     headers: {
                    //     'Content-Type': 'application/json',
                    //     'Authorization': "Bearer "+accessTokenIn
                    //     },
                    //     body: JSON.stringify(requestObject)
                    // };

                    $(".load-wrap").show(); //console.log(requestObject); return false;
                    services.updateClientById(requestObject, editClient).then((res) => {
                        setIsOpen(false);
                        $("#entryform").trigger("reset");
                        $(".load-wrap").show();
                        setShowLoader(0);
                        $("#updatesuccessMessageClient").fadeTo(2000, 500).slideUp(500, function () {
                            $("#updatesuccessMessageClient").slideUp(500);
                        });
                        setTimeout(function() { $('#closemodal').click();
                            window.location.href='/project-management'; }, 1000);
                    }); 
            }
        }
        else {
            //add form
            var validation = 0;
            if(isEmpty($('#client_name').val())){
                validation = 1;
                setClientvalidation(1);
            } else {
                setClientvalidation(0);
            }

            if(isEmpty($('#company_name').val())){
                validation = 1;
                setcompanyvalidation(1);
            } else {
                setcompanyvalidation(0);
            }

            if(validation == 1){
                $('#validationMessage').show();
                return false;
            } else {
                setShowLoader(1);
                const requestObject =
                {
                    "client_name": $('#client_name').val(),
                    "address1": null,
                    "city": null,
                    "state": null,
                    "zip": null,
                    "company_id": sesAdminCompany,//$('#company_name').val(),
                    "status": 1
                }

                
                var accessTokenIn=sessionStorage.getItem("accToken");
                // const requestOptions = {
                //   method: 'POST',
                //   headers: {
                //     'Content-Type': 'application/json',
                //     'Authorization': "Bearer "+accessTokenIn
                //   },
                //   body: JSON.stringify(requestObject)
                // };

                $(".load-wrap").show();
                services.createClient(requestObject).then((res) => {
                    
                    if(res.type=='success'){
                        $("#entryform").trigger("reset");
                        setIsOpen(false);
                        $(".load-wrap").show();
                        setShowLoader(0);
                        $("#successMessageClient").fadeTo(2000, 500).slideUp(500, function () {
                            $("#successMessageClient").slideUp(500);
                        });
                        setTimeout(function() { $('#closemodal').click();
                        window.location.href='/project-management';
                        }, 1000);
                    }
                    if(res.type=='error'){
                        $("#entryform").trigger("reset");
                        setIsOpen(false);
                        $("#failuresuccessMessageClient").html(res.message);
                        $(".load-wrap").show();
                        setShowLoader(0);
                        $("#failuresuccessMessageClient").fadeTo(2000, 500).slideUp(500, function () {
                            $("#failuresuccessMessageClient").slideUp(500);
                        });
                        setTimeout(function() { $('#closemodal').click();
                        window.location.href='/project-management';
                        }, 1000);
                    }
                });
            }
        }

    }

    const isEmpty = (value) => {
        return value == null || String(value).trim() === '';
      }
      const getTitleHead = () => {
        //$("#entryform").trigger("reset");
        if(!isEmpty(editClient)){
            return 'Edit Client';
        }
        else {
            return 'Add Client';
        }
      }

      const handleCompany = (event) => {
        setClientCompany(event.target.value);
    }

    const [inputValue, setInputValue] = useState('');

  const handleChange = (event) => {
    const { value } = event.target;
    // Capitalize the first letter of each word
    const capitalizedValue = value
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    
    setInputValue(capitalizedValue);
  };

  const handleEditChange = (event) => {
    const { value } = event.target;
    // Capitalize the first letter of each word
    const capitalizedValue = value
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    
      $('#editclient_name').val(capitalizedValue);
  };

  return(
  <>
      <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
        <form id="entryform" onSubmit={handleSubmit(submitValue)}>
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{getTitleHead()}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                {showLoader === 1 ?
    <div class="loadwrapperpopup">
        <div class="clock"><div class="circle"></div></div>
    </div>
    : '' }

                <div class="modal-body" id="clientadd">

                    <div class="form-group mb-2">
                        <label for="recipient-name" class="col-form-label">Client*</label>
                        <input type="text" class={`form-control ${clientvalidation === 1 ? 'errorinp' : ''}`} id="client_name" name="client_name" placeholder="Client Name" value={inputValue} onChange={handleChange}/>
                    </div>

                    <div class="form-group mb-2">
                        <label for="recipient-name" class="col-form-label">Company*</label>
                        <select  class="form-select" id="company_name" name="company_name" aria-label="Default select example">
                            {/* <option value='' selected>Select Company</option> */}
                            {showCompanyList.map((data, index) => (
                                <option value={data.id} >{data.company_name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="validationglobal p-2" id="validationMessage">
                        <div className="validateitem">
                            {/* <span className="errimg"><img class="icon" src="images/error.svg" alt=""/></span> */}
                            <p>Please fill out all the required fields!</p>
                        </div>
                    </div>
                </div>

                <div class="modal-body" id="clientedit">

                    <div class="form-group mb-2">
                        <label for="recipient-name" class="col-form-label">Client*</label>
                        <input type="text" class={`form-control ${editclientvalidation === 1 ? 'errorinp' : ''}`} id="editclient_name" name="editclient_name" defaultValue={clientName} onChange={handleEditChange} placeholder="Client Name"/>
                    </div>

                    <div class="form-group mb-2">
                        <label for="recipient-name" class="col-form-label">Company*</label>
                        <select class="form-select" id="editcompany_name" name="editcompany_name" aria-label="Default select example" value={clientCompany} disabled onChange={handleCompany}>
                            {showCompanyList.map((data, index) => (
                                <option value={data.id} >{data.company_name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="validationglobal p-2" id="editvalidationMessage">
                        <div className="validateitem">
                            {/* <span className="errimg"><img class="icon" src="images/error.svg" alt=""/></span> */}
                            <p>Please fill out all the required fields!</p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary">Save</button>
                    <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal">Cancel</button>

                </div>
                <div class="px-3">
                <div id="successMessageClient" class="alert alert-success" role="alert">Client Created successfully!</div>
                <div id="updatesuccessMessageClient" class="alert alert-success" role="alert">Client Updated successfully!</div>
                <div id="failuresuccessMessageClient" class="alert alert-danger" role="alert"></div>
                </div>
            </div>
        </form>
        </div>
    </div>

  </>
)};

AddClient.propTypes = {};

AddClient.defaultProps = {};

export default AddClient;
